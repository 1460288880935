import { createApp } from 'vue';
import App from './App.vue';
import router from './router/';
import i18n from './i18n';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import loadingDirectives from './directives';
import SvgIcon from '@/components/SvgIcon.vue';
import Notifications from '@kyvg/vue3-notification';
import FloatingVue from 'floating-vue';
import './assets/css/main.postcss';

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;

  app.use(router);
  app.use(i18n);
  app.use(createPinia());

  // Plugins
  app.use(Notifications);
  app.use(FloatingVue);

  // Directives
  app.directive('loading', loadingDirectives);

  // Global Compontents
  app.component('SvgIcon', SvgIcon);

  app.mount('#app');

  // Sentry
  if (['staging', 'production'].includes(import.meta.env.NODE_ENV)) {
    Sentry.attachErrorHandler(app, {
      logErrors: true,
    });

    Sentry.init({
      app,
      environment: import.meta.env.NODE_ENV,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [import.meta.env.VITE_APP_API_URL],
        }),
      ],
      tracesSampleRate: 1.0,
      release: import.meta.env.VITE_RELEASE_TAG,
    });
  }
};

app = createApp(App);

setupApp(app);
