<template>
  <div class="app-master">
    <Teleport to="body">
      <svg class="absolute pointer-events-none w-[0px] h-[0px]" viewBox="0 0 1 1">
        <defs>
          <linearGradient id="fade-gradient-v" x1="0" x2="0" y1="0" y2="1">
            <stop stop-color="white" offset="0%" />
            <stop stop-color="black" offset="90%" />
          </linearGradient>
          <linearGradient id="fade-gradient-h" x1="0" x2="1" y1="0" y2="0">
            <stop stop-color="white" offset="0%" />
            <stop stop-color="black" offset="90%" />
          </linearGradient>
        </defs>
        <mask id="fade-opacity-v" maskContentUnits="objectBoundingBox">
          <rect fill="url(#fade-gradient-v)" x="0" y="0" width="1" height="1" />
        </mask>
        <mask id="fade-opacity-h" maskContentUnits="objectBoundingBox">
          <rect fill="url(#fade-gradient-h)" x="0" y="0" width="1" height="1" />
        </mask>
      </svg>
    </Teleport>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Transition name="fade" mode="out-in">
          <Suspense>
            <template #fallback>
              <div class="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                <img class="p-4" src="./assets/images/anresis_guide_logo_rgb.png" alt="Logo of Anresis">
              </div>
            </template>
            <component :is="Component" />
          </Suspense>
        </Transition>
      </template>
    </RouterView>
  </div>
</template>

<script setup>
import { setLanguage } from '@/services/language.service';

setLanguage('en')
</script>

<style>
.app-master {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.15s ease-in-out;
  opacity: 1;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
